/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetBlogByIdQuery } from "../../../services/cms";
import { Loader, errorToast } from "../../../helpers";
import { Blog } from "../../../types/General";
import { useEffect, useState } from "react";
import moment from "moment";

const BlogBotContent = () => {
  return (
    <section className="joinMovement_sc bg_primary u_spc">
      <div className="conta_iner">
        <div className="inner">
          <div className="s_head text_center text_white">
            <h2>Recycle your e-waste, one deposit at a time. Start today</h2>
          </div>
          <div className="btn_flex">
            <a target="_blank" href="https://t.me/bantgo_bot" className="btn btn_lg btn_white br" >
              Telegram
            </a>
            <a target="_blank" href="https://wa.me/971553386994?text=start" className="btn btn_lg btn_white br" >
              Whatsapp
            </a>
          </div>
        </div>
      </div>
    </section >
  );
};

export default BlogBotContent;
