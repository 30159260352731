/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetMyClaimedCouponsQuery } from "../../../services/main/setting";
import { Loader } from "../../../helpers";
import NoDataFound from "../../../components/noData";
import SitePagination from "../../../components/sitePagination";

const ClaimedCoupons = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const [getMyCouponsMethod] = useLazyGetMyClaimedCouponsQuery();

  const getMyClaimedCoupons = async () => {
    setIsLoading(true);
    try {
      const result = await getMyCouponsMethod({
        page: page,
        size: 12,
      }).unwrap();
      if (result?.statusCode === 200) {
        setCoupons(result?.data?.data || []);
        setTotalCount(result?.data?.count || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getMyClaimedCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <h3>Claimed Coupons</h3>
      <div className="coupons_listing">
        <div className="gap_m">
          {coupons?.length
            ? coupons?.map((item: any) => (
                <div
                  onClick={() =>
                    navigate(`/nft-detail/${item?.nftId?._id}?type=3&path=acc`)
                  }
                  className="coupon_card_dtl"
                  key={item?._id}
                >
                  <figure>
                    <img src={"/images/bar_code.svg"} alt="" />
                    <span>Gift Voucher</span>
                  </figure>
                  <div className="coupon_dtl text_center">
                    <figure className="uploaded_coupon">
                      <img
                        src={
                          item?.couponId?.image ||
                          item?.couponId?.assignedTo[0]?.image ||
                          "/images/placeholder_user_icon.png"
                        }
                        alt=""
                      />
                    </figure>
                    {/* <h2>
                      Special <span className="d_block">Offer</span>
                    </h2>
                    <p>{`Valid upto ${moment(item?.couponId?.endDate).format(
                      "DD MMM YYYY"
                    )}`}</p> */}
                  </div>
                  <div className="coupon_btn">
                    <button className="btn btn_primary">
                      <span>
                        {item?.couponId?.discountType === 1
                          ? `AED ${item?.couponId?.effectiveValue}`
                          : `${item?.couponId?.effectiveValue}%`}
                      </span>
                    </button>
                  </div>
                </div>
              ))
            : undefined}
        </div>
        {coupons?.length ? (
          <SitePagination
            module={coupons}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
            setPage={setPage}
          />
        ) : undefined}
      </div>
      {!isLoading && !coupons?.length ? (
        <NoDataFound image={"/images/noData.png"} text="No Data Found" />
      ) : undefined}
    </>
  );
};

export default ClaimedCoupons;
